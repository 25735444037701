<template>
	<div class="container">
		<lightBox ref="lightBox" :actIndex=actIndex :images=images  :animation="true"/>
		<div class="galerie">

			<div class="hl1" style="text-align: center; margin: 1rem;"><h3>Unsere Arbeiten</h3></div>


			<div v-if="galDat" class="galList">
				<div v-for="(v,i) in galDat" class="item">
					<div><img :src="$store.state.server + '/img/ua/' + v.img_thumbnail" :alt="v.alt_text" :title="v.title" @click="showLighBoxMulti"></div>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	import axios from 'axios';
	import  lightBox from "@/components/LightBox.vue";
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'UnsereArbeiten',
		components: {
			lightBox,
		},
		mounted(){
			this.getIndex();

		}, // end mounted 
		data: 
		function(){
			return {
				galDat: null,
				images: [],
				actIndex: null,
			}
		}, // end data
		methods: {
			getIndex(){
				let self = this;
				let url = this.$store.state.server + '/api/getUAIndex.php';



				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { kat: this.selKat},
				})
				.then(function (resp) {

					self.galDat = resp.data.data[0];

				});
			},
			showLighBoxMulti(e){

				this.images = []
				let coll = document.getElementsByClassName("container")[0].getElementsByTagName("img");

				let selSrc = e.target.src;

				let i = -1;
				for (let item of coll) {
					i++;

					if (item.src === selSrc) {
						this.actIndex = i;
					}


					let newSrc = item.src.replace(/ua-tn/, 'ua-fl');


					this.images.push({src: newSrc, height: item.naturalHeight, width: item.naturalWidth, title: item.title, alt: item.alt});
				}
			}


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

 .galerie {
	// display: block;
	// justify-content: center;

	// width: max-content;
	// margin: 0 auto;
	

	
}

.galerie .galList {
	// flex: 0 0 100%;
	// border: 1px solid silver;
	
	
		
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	& > div.item {
		box-sizing: border-box;
		// flex: 1 0 33%;
		padding: .3rem;


		& img {
				border: 6px solid white;
				box-shadow: 1px 1px 2px #ddd;
					opacity: 1;

					&:hover {
						opacity: .9;
						// border-color: #ddd;
						box-shadow: 1px 1px 2px black;
						transition-duration: .5s;

					}
		}
	}
}

.galerie  img {
	cursor: pointer;
}

</style>
